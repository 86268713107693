<template>
  <modal-2 ref="modal">
    <template slot="title">
      {{ isEditing ? "Editar Opción" : "Nueva Opción" }}
    </template>

    <custom-form @submit="onSubmit">
      <custom-input v-model="option.text" label="Nombre" rules="required" />

      <checkbox v-model="option.isPositive" label="Opción Positiva" />

      <template slot="buttons">
        <custom-button
          type="button"
          variant="secondary"
          @click="clearAndExit"
          :disabled="isLoading"
        >
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="primary" :disabled="isLoading">
          {{
            isEditing
              ? isLoading
                ? "Guardando..."
                : "Guardar"
              : isLoading
              ? "Agregando..."
              : "Agregar"
          }}
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Checkbox from "../../components/Checkbox.vue";
import CustomButton from "../../components/CustomButton.vue";
import CustomForm from "../../components/CustomForm.vue";
import CustomInput from "../../components/CustomInput.vue";
import Modal2 from "../../components/Modal2.vue";

export default {
  components: { Modal2, CustomForm, CustomInput, Checkbox, CustomButton },

  data: () => ({
    isLoading: false,
    isEditing: false,

    optionId: "",

    option: {
      text: "",
      isPositive: false,
      order: 0
    }
  }),

  computed: {
    ...mapState("scale", ["scale"])
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions("scale", ["addOption", "updateOption"]),

    open() {
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    },

    edit(option) {
      this.option = JSON.parse(JSON.stringify(option));

      this.isEditing = true;

      this.open();
    },

    clearAndExit() {
      this.option = {
        text: "",
        isPositive: false,
        order: 0
      };
      this.optionId = "";
      this.isEditing = false;

      this.close();
    },

    async onSubmit() {
      try {
        this.isLoading = true;

        if (this.isEditing) {
          await this.updateOption({
            id: this.optionId,
            ...this.option
          });
        } else {
          await this.addOption({
            ...this.option,
            order: this.scale.length
          });
        }

        this.setAlert({
          state: "success",
          message: this.isEditing
            ? "Se actualizó la opción"
            : "Se agregó la opción"
        });

        this.clearAndExit();
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: "Hubo un problema, por favor inténtalo de nuevo"
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style></style>
