<template>
  <article v-if="!isSorting" class="option">
    <span class="number">{{ index + 1 }}</span>

    <h4>{{ option.text }}</h4>

    <span
      :class="{
        'tag--green': option.isPositive,
        'tag--red': !option.isPositive
      }"
      class="tag"
    >
      {{ option.isPositive ? "Positiva" : "Negativa" }}
    </span>

    <custom-button
      type="button"
      variant="secondary"
      @click="$emit('edit', option)"
    >
      Editar
    </custom-button>

    <custom-button
      type="button"
      variant="secondary"
      @click="$emit('delete', { id: option.id, order: option.order })"
    >
      Eliminar
    </custom-button>
  </article>

  <sortable-item v-else :index="index">
    <div class="option">
      <span class="number">{{ index + 1 }}</span>

      <h4>{{ option.text }}</h4>

      <span
        :class="{
          'tag--green': option.isPositive,
          'tag--red': !option.isPositive
        }"
        class="tag"
      >
        {{ option.isPositive ? "Positiva" : "Negativa" }}
      </span>
    </div>
  </sortable-item>
</template>

<script>
import CustomButton from "../components/CustomButton.vue";
import SortableItem from "./SortableItem.vue";

export default {
  name: "ScaleOptionCard",

  components: { CustomButton, SortableItem },

  props: {
    option: Object,
    index: Number,
    isSorting: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style scoped>
.option {
  display: flex;
  align-items: center;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
  padding: var(--lengthMd1);
}

.list-item {
  align-items: center;
}

.list-item:nth-child(odd),
.option:nth-child(odd) {
  background-color: #f7f7f7;
}

.option .btn:first-of-type {
  margin-left: auto;
}

.tag {
  padding: var(--lengthSm1);
  font-size: 0.75rem;
  font-weight: var(--medium);
  text-transform: uppercase;
  background-color: var(--grayColor1);
  border-radius: 4px;
}

.tag--green {
  color: var(--successColor);
  background-color: var(--successColorSoft);
}

.tag--red {
  color: var(--dangerColor);
  background-color: var(--dangerColorSoft);
}

.number {
  width: var(--lengthMd3);
  height: var(--lengthMd3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.75rem;
  font-weight: var(--medium);
  background-color: var(--mainColor2);
  border-radius: 50%;
}
</style>
