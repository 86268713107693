<template>
  <modal-2 ref="modal">
    <template slot="title">Elegir Plantilla</template>

    <multi-step-form
      :currentStep="currentStep"
      :steps="steps"
      submitButtonText="Guardar"
      @changeStep="currentStep = $event"
      @submit="onSubmit"
      @cancel="onCancel"
    >
      <template v-if="currentStep === 0">
        <radio-button-group
          groupName="template"
          :options="templates"
          v-model="selectedTemplate"
        />

        <h4>{{ `Plantilla Elegida: ${selectedTemplate.name || "-"}` }}</h4>

        <ul>
          <li v-for="(option, index) in selectedTemplate.options" :key="index">
            {{ option.text }}
          </li>
        </ul>
      </template>

      <template v-else-if="currentStep === 1">
        <div class="btn-container">
          <custom-button variant="secondary" type="button" @click="addOption">
            <font-awesome-icon icon="plus" slot="icon" />
            Agregar Opción
          </custom-button>
        </div>

        <sortable-list
          v-model="selectedTemplate.options"
          lockAxis="y"
          :useDragHandle="true"
          :lockToContainerEdges="true"
        >
          <sortable-item
            v-for="(option, index) in selectedTemplate.options"
            :index="index"
            :key="index"
          >
            <custom-input
              v-model="option.text"
              :id="`option-${index}`"
              :label="`Opción ${index + 1}`"
              placeholder="Escriba aquí"
              rules="required"
            />

            <checkbox v-model="option.isPositive" label="Positivo" />

            <custom-button
              variant="secondary"
              type="button"
              @click="deleteOption(index)"
            >
              <font-awesome-icon icon="times" />
            </custom-button>
          </sortable-item>
        </sortable-list>
      </template>
    </multi-step-form>

    <confirm-dialogue ref="confirmDialogue" />
  </modal-2>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import SortableList from "../../components/SortableList.vue";
import SortableItem from "../../components/SortableItem.vue";
import Checkbox from "../../components/Checkbox.vue";
import MultiStepForm from "../../components/MultiStepForm.vue";
import CustomButton from "../../components/CustomButton.vue";
import CustomInput from "../../components/CustomInput.vue";
import Modal2 from "../../components/Modal2.vue";
import RadioButtonGroup from "../../components/RadioButtonGroup.vue";
import ConfirmDialogue from "../../components/ConfirmDialogue.vue";

export default {
  name: "ScaleTemplatesModal",

  components: {
    SortableItem,
    SortableList,
    Checkbox,
    MultiStepForm,
    CustomButton,
    CustomInput,
    Modal2,
    RadioButtonGroup,
    ConfirmDialogue
  },

  data: () => ({
    templates: [
      {
        id: "two-options",
        name: "2 Opciones",
        options: [
          {
            isPositive: true,
            text: "De acuerdo"
          },
          {
            isPositive: false,
            text: "En desacuerdo"
          }
        ]
      },
      {
        id: "three-options",
        name: "3 Opciones",
        options: [
          {
            isPositive: true,
            text: "De acuerdo"
          },
          {
            isPositive: false,
            text: "Ni de acuerdo, ni en desacuerdo"
          },
          {
            isPositive: false,
            text: "En desacuerdo"
          }
        ]
      },
      {
        id: "four-options",
        name: "4 Opciones",
        options: [
          {
            isPositive: true,
            text: "Totalmente de acuerdo"
          },
          {
            isPositive: true,
            text: "De acuerdo"
          },
          {
            isPositive: false,
            text: "En desacuerdo"
          },
          {
            isPositive: false,
            text: "Totalmente en desacuerdo"
          }
        ]
      },
      {
        id: "five-options",
        name: "5 Opciones",
        options: [
          {
            isPositive: true,
            text: "Totalmente de acuerdo"
          },
          {
            isPositive: true,
            text: "De acuerdo"
          },
          {
            isPositive: false,
            text: "Ni de acuerdo, ni en desacuerdo"
          },
          {
            isPositive: false,
            text: "En desacuerdo"
          },
          {
            isPositive: false,
            text: "Totalmente en desacuerdo"
          }
        ]
      },
      {
        id: "three-options-emojis",
        name: "3 Opciones Emojis",
        options: [
          {
            isPositive: false,
            text: "🙁"
          },
          {
            isPositive: false,
            text: "😐"
          },
          {
            isPositive: true,
            text: "🙂"
          }
        ]
      },
      {
        id: "five-options-emojis",
        name: "5 Opciones Emojis",
        options: [
          {
            isPositive: false,
            text: "😞"
          },
          {
            isPositive: false,
            text: "🙁"
          },
          {
            isPositive: false,
            text: "😐"
          },
          {
            isPositive: true,
            text: "🙂"
          },
          {
            isPositive: true,
            text: "😀"
          }
        ]
      }
    ],

    currentStep: 0,
    steps: ["Elegir Plantilla", "Personalizar Opciones"],

    selectedTemplate: {}
  }),

  computed: {
    ...mapState("scale", ["scale"])
  },

  methods: {
    ...mapActions("scale", ["replaceScale"]),

    ...mapMutations(["setAlert"]),

    deleteOption(index) {
      if (this.selectedTemplate.options.length <= 1) return;

      this.selectedTemplate.options.splice(index, 1);
    },

    addOption() {
      this.selectedTemplate.options.push({
        text: "",
        isPositive: false
      });
    },

    setScale(e) {
      const index = e.target.value;

      if (index == this.templates.length)
        Object.assign(this.options, this.currentScale);
      else Object.assign(this.options, this.templates[index].options);
    },

    onCancel() {
      this.currentStep = 0;
      this.selectedTemplate = {};
      this.close();
    },

    async onSubmit() {
      const scale = this.selectedTemplate.options.map((opt, order) => ({
        ...opt,
        order
      }));

      let ok = true;
      if (this.scale.length) {
        ok = await this.$refs.confirmDialogue.show({
          title: "Crear nueva escala",
          message:
            "¿Estás seguro que quieres crear esta escala? Esto remplazará todas las opciones que tenías previamente.",
          okButton: "Sí, crear",
          cancelButton: "No, volver"
        });
      }

      if (ok) {
        try {
          await this.replaceScale(scale);

          this.setAlert({
            state: "success",
            message: "Se creó la nueva escala"
          });

          this.onCancel();
        } catch (err) {
          console.log(err);

          this.setAlert({
            state: "error",
            message: "Ocurrió un error, por favor vuelva a intentarlo"
          });
        }
      }
    },

    open() {
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    }
  },

  mounted() {
    this.selectedTemplate = this.templates[0];
  }
};
</script>

<style scoped>
.input {
  flex-grow: 1;
}

.list-item {
  align-items: center;
}
</style>
