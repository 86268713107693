<template>
  <ValidationObserver
    tag="div"
    v-slot="{ handleSubmit }"
    class="form-container"
  >
    <form class="form" @submit.prevent="handleSubmit(onSubmit)">
      <slot></slot>

      <div v-if="$slots.buttons" class="buttons">
        <slot name="buttons"></slot>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  name: "CustomForm",

  components: { ValidationObserver },

  methods: {
    onSubmit(e) {
      this.$emit("submit", e);
    }
  }
};
</script>

<style scoped>
.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}
</style>
