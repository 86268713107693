<template>
  <div>
    <view-hero>
      <template slot="title">Escalas</template>
      <template slot="description">
        Puedes elegir la escala que se adecue a los objetivos de tu
        organización. La precisión en la respuestas de tus encuestas, te
        brindará mejores resultados.
      </template>
    </view-hero>
    <custom-section>
      <template v-if="scale.length">
        <div v-if="!isSorting" class="btn-container">
          <custom-button type="button" variant="primary" @click="onAdd">
            <font-awesome-icon icon="plus" slot="icon" />
            Agregar Opci&oacute;n
          </custom-button>
          <custom-button
            type="button"
            variant="secondary"
            @click="$refs.scaleTemplatesModal.open()"
          >
            Elegir Plantilla
          </custom-button>
          <custom-button type="button" variant="secondary" @click="onSort">
            <font-awesome-icon :icon="icons.sort" slot="icon" />
            Ordenar
          </custom-button>
        </div>

        <div v-else class="btn-container">
          <custom-button
            type="button"
            variant="secondary"
            @click="isSorting = false"
            :disabled="isLoading"
          >
            Cancelar
          </custom-button>
          <custom-button
            type="button"
            variant="primary"
            :disabled="isLoading"
            @click="onOrderSubmit"
          >
            {{ !isLoading ? "Guardar Orden" : "Guardando..." }}
          </custom-button>
        </div>

        <div class="info" v-if="isSorting">
          <font-awesome-icon :icon="icons.info" size="lg" />
          <p>
            Recuerda que el orden de visualización de las opciones será de
            arriba a abajo en dispositivos móviles y de izquierda a derecha en
            tablets y laptops.
          </p>
        </div>

        <component
          :is="isSorting ? 'sortable-list' : 'div'"
          v-model="scaleCopy"
          lockAxis="y"
          :useDragHandle="true"
          :lockToContainerEdges="true"
        >
          <scale-option-card
            v-for="(option, index) in isSorting ? scaleCopy : scale"
            :key="option.id"
            :option="option"
            :index="index"
            :isSorting="isSorting"
            @edit="onEdit"
            @delete="onDelete"
          />
        </component>
      </template>

      <empty-screen
        v-else
        :icon="icons.emptyScreen"
        @ctaClick="$refs.scaleTemplatesModal.open()"
      >
        <template slot="title">Crea tu Escala</template>
        <template slot="description">
          Para crear tu escala, presiona el botón inferior y elige una de las
          opciones de nuestra lista de plantillas y personalizala a tus
          requerimientos.
        </template>
        <template slot="btnText">Elegir Plantilla</template>
      </empty-screen>
    </custom-section>

    <scale-templates-modal ref="scaleTemplatesModal" />

    <option-modal ref="optionModal" />

    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import {
  faInfoCircle,
  faList,
  faSort
} from "@fortawesome/free-solid-svg-icons";

import CustomSection from "../../components/CustomSection.vue";
import Modal from "../../components/Modal.vue";
import ScaleTemplatesModal from "./ScaleTemplatesModal.vue";
import ViewHero from "../../components/ViewHero.vue";
import EmptyScreen from "../EmptyScreen.vue";
import CustomButton from "../../components/CustomButton.vue";
import OptionModal from "./OptionModal.vue";
import ScaleOptionCard from "../../components/ScaleOptionCard.vue";
import ConfirmDialogue from "../../components/ConfirmDialogue.vue";
import SortableList from "../../components/SortableList.vue";

export default {
  name: "Scale",

  components: {
    CustomSection,
    Modal,
    ScaleTemplatesModal,
    ViewHero,
    EmptyScreen,
    CustomButton,
    OptionModal,
    ScaleOptionCard,
    ConfirmDialogue,
    SortableList
  },

  data: () => ({
    editScaleModal: false,

    isSorting: false,
    isLoading: false,

    icons: {
      emptyScreen: faList,
      sort: faSort,
      info: faInfoCircle
    },

    scaleCopy: []
  }),

  computed: {
    ...mapState({
      scale: state => state.scale.scale,
      survey: state => state.survey
    })
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions("scale", ["fetchScale", "deleteOption", "updateOrder"]),

    onAdd() {
      this.$refs.optionModal.open();
    },

    onEdit(option) {
      this.$refs.optionModal.edit(option);
    },

    onSort() {
      this.scaleCopy = JSON.parse(JSON.stringify(this.scale));
      this.isSorting = true;
    },

    async onOrderSubmit() {
      const newOrderScale = this.scaleCopy.map(({ id }, index) => ({
        id,
        order: index
      }));

      try {
        this.isLoading = true;

        await this.updateOrder(newOrderScale);

        this.isSorting = false;
        this.scaleCopy = [];

        this.setAlert({
          state: "success",
          message: "Se actualizó el orden de las opciones"
        });
      } catch (error) {
        console.log(error);

        this.setAlert({
          state: "error",
          message: "Hubo un problema, por favor inténtalo de nuevo"
        });
      } finally {
        this.isLoading = false;
      }
    },

    async onDelete({ id, order }) {
      const ok = await this.$refs.confirmDialogue.show({
        title: "Eliminar Opción",
        message: "¿Estás seguro que quieres eliminar esta opción?",
        okButton: "Sí, eliminar"
      });

      if (ok) {
        try {
          await this.deleteOption({ id, order });

          this.setAlert({
            state: "success",
            message: "Se eliminó la opción"
          });
        } catch (error) {
          console.log(error);

          this.setAlert({
            state: "error",
            message: "Hubo un problema, por favor inténtalo de nuevo"
          });
        }
      }
    }
  },

  mounted() {
    if (!this.scale.length) this.fetchScale();
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.survey.active) next("/");
      else next();
    });
  }
};
</script>

<style scoped>
.btn-container {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}

.info {
  display: flex;
  align-items: center;
  grid-gap: var(--lengthSm2);
  padding: var(--lengthMd1);
  color: var(--infoColor);
  font-weight: var(--medium);
  background-color: var(--infoColorSoft);
  border-left: 3px solid currentColor;
}

.hero {
  background-image: url("../../assets/modules/scale.jpg");
}
</style>
